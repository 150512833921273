import React, { useRef, useState } from "react"
import { Form, Button, Card, Alert } from "react-bootstrap"
import { useAuth } from "../contexts/AuthContext"
import { Link, useHistory } from "react-router-dom"
import "./maintheme.css"

export default function Login() {
	const emailRef = useRef()
	const passwordRef = useRef()
	const { login } = useAuth()
	const [error, setError] = useState("")
	const [loading, setLoading] = useState(false)
	const history = useHistory()

	async function handleSubmit(e) {
		e.preventDefault()

		try {
			setError("")
			setLoading(true)
			await login(emailRef.current.value, passwordRef.current.value)
			history.push("/")
		} catch {
			setError("Failed to log in")
		}

		setLoading(false)
	}

	return (
		<>
			<Card className="bg-dark">
				<Card.Body>
					<h2 className="text-center mb-4 text-main">Log In to Tweedle</h2>
					{error && <Alert variant="danger">{error}</Alert>}
					<Form onSubmit={handleSubmit}>
						<Form.Group id="email">
							<Form.Label className="text-main">Email</Form.Label>
							<Form.Control type="email" className="bg-dark text-light" ref={emailRef} required />
						</Form.Group>
						<Form.Group id="password">
							<Form.Label className="text-main">Password</Form.Label>
							<Form.Control type="password" className="bg-dark text-light" ref={passwordRef} required />
						</Form.Group>
						<Button disabled={loading} className="w-100" type="submit">
							Log In
						</Button>
					</Form>
					<div className="w-100 text-center mt-3 ">
						<Link to="/forgot-password">Forgot Password?</Link>
					</div>
				</Card.Body>
			</Card>
			<div className="w-100 text-center mt-2 text-main">
				Need an account? <Link to="/signup">Sign Up</Link>
			</div>
		</>
	)
}
