import React, { useRef, useState } from "react"
import { Form, Button, Card, Alert } from "react-bootstrap"
import { useAuth } from "../contexts/AuthContext"
import { Link, useHistory } from "react-router-dom"
import "./maintheme.css"

export default function Signup() {
	const emailRef = useRef()
	const passwordRef = useRef()
	const passwordConfirmRef = useRef()
	const { signup } = useAuth()
	const [error, setError] = useState("")
	const [loading, setLoading] = useState(false)
	const history = useHistory()

	async function handleSubmit(e) {
		e.preventDefault()

		if (passwordRef.current.value !== passwordConfirmRef.current.value) {
			return setError("Passwords do not match")
		}

		try {
			setError("")
			setLoading(true)
			await signup(emailRef.current.value, passwordRef.current.value)
			history.push("/")
		} catch {
			setError("Failed to create an account")
		}

		setLoading(false)
	}

	return (
		<>
			<Card className="bg-dark">
				<Card.Body>
					<h2 className="text-center mb-4 text-main">Sign Up for Tweedle</h2>
					{error && <Alert variant="danger">{error}</Alert>}
					<Form onSubmit={handleSubmit}>
						<Form.Group id="username">
							<Form.Label className="text-main">Username</Form.Label>
							<Form.Control type="username" className="bg-dark text-light" ref={emailRef} required />
						</Form.Group>
						<Form.Group id="email">
							<Form.Label className="text-main">Email</Form.Label>
							<Form.Control type="email" className="bg-dark text-light" ref={emailRef} required />
						</Form.Group>
						<Form.Group id="password">
							<Form.Label className="text-main">Password</Form.Label>
							<Form.Control type="password" className="bg-dark text-light" ref={passwordRef} required />
						</Form.Group>
						<Form.Group id="password-confirm">
							<Form.Label className="text-main">Retype Password</Form.Label>
							<Form.Control type="password" className="bg-dark text-light" ref={passwordConfirmRef} required />
						</Form.Group>
						<Button disabled={loading} className="w-100" type="submit">
							Sign Up
						</Button>
					</Form>
				</Card.Body>
			</Card>
			<div className="w-100 text-center mt-2 text-main">
				Already a Tweedler? <Link to="/login">Log In.</Link>
			</div>
		</>
	)
}
