import firebase from "firebase/app"
import "firebase/auth"

const app = firebase.initializeApp({
	apiKey: "AIzaSyCn9A-Ok1O6K8OJqLfjifV0KgXE300vNwU",
	authDomain: "tweedle-music.firebaseapp.com",
	projectId: "tweedle-music",
	storageBucket: "tweedle-music.appspot.com",
	messagingSenderId: "551313689056",
	appId: "1:551313689056:web:c4b63c7b1f6e6b03f54385",
	measurementId: "G-H4MQWL53XY"
})

export const auth = app.auth()
export default app