import React, { useRef, useState } from "react"
import { Form, Button, Card, Alert } from "react-bootstrap"
import { useAuth } from "../contexts/AuthContext"
import { Link, useHistory } from "react-router-dom"
import "./maintheme.css"

export default function UpdateProfile() {
	const emailRef = useRef()
	const passwordRef = useRef()
	const passwordConfirmRef = useRef()
	const { currentUser, updatePassword, updateEmail } = useAuth()
	const [error, setError] = useState("")
	const [loading, setLoading] = useState(false)
	const history = useHistory()

	function handleSubmit(e) {
		e.preventDefault()
		if (passwordRef.current.value !== passwordConfirmRef.current.value) {
			return setError("Passwords do not match")
		}

		const promises = []
		setLoading(true)
		setError("")

		if (emailRef.current.value !== currentUser.email) {
			promises.push(updateEmail(emailRef.current.value))
		}
		if (passwordRef.current.value) {
			promises.push(updatePassword(passwordRef.current.value))
		}

		Promise.all(promises)
			.then(() => {
				history.push("/")
			})
			.catch(() => {
				setError("Failed to update account")
			})
			.finally(() => {
				setLoading(false)
			})
	}

	return (
		<>
			<Card className="bg-dark">
				<Card.Body>
					<h2 className="text-center mb-4 text-main">Update Profile</h2>
					{error && <Alert variant="danger">{error}</Alert>}
					<Form onSubmit={handleSubmit}>
						<Form.Group id="email">
							<Form.Label className="text-main">Email</Form.Label>
							<Form.Control
								type="email"
								ref={emailRef}
								required
								className="bg-dark text-light"
								defaultValue={currentUser.email}
							/>
						</Form.Group>
						<Form.Group id="password">
							<Form.Label className="text-main">Password</Form.Label>
							<Form.Control
								type="password"
								ref={passwordRef}
								className="bg-dark text-light"
								placeholder="Leave blank to keep the same"
							/>
						</Form.Group>
						<Form.Group id="password-confirm">
							<Form.Label className="text-main">Password Confirmation</Form.Label>
							<Form.Control
								type="password"
								ref={passwordConfirmRef}
								className="bg-dark text-light"
								placeholder="Leave blank to keep the same"
							/>
						</Form.Group>
						<Button disabled={loading} className="w-100" type="submit">
							Update
						</Button>
					</Form>
				</Card.Body>
			</Card>
			<div className="w-100 text-center mt-2">
				<Link to="/">Cancel</Link>
			</div>
		</>
	)
}
